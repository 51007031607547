.checkout {
  .address-form {
    .form-item {
      padding-left: 20px;
    }
  }
  .payment-option {
    width: 100%;
    display: block;
    label {
      vertical-align: top;
      line-height: 2.2;
      .related-media {
        img {
          height: auto;
        }
      }
    }
  }
  .security-info {
    p {
      text-align: center;
    }
  }
  div.expires-month {
    width: 27%;
    input.expiration_month {
      width: 100%;
    }
  }
  div.expires-year {
    float: left;
    width: 30%;
    input.expiration_year {
      width: 100%;
    }
  }
  div.cvv {
    float: left;
    width: 43%;
  }
  div.parcelas {
    float: left;
    position: relative;
    top: -25px;
    width: 100%;
  }
  section.viewcart-buttons-panel {
    .content {
      a.continue {
        &-shopping {
          display: block !important;
          text-decoration: none;
        }
        &-checkout.disabled {
          background-color: $color-light-gray;
          border: 1px solid $color-light-gray;
        }
      }
    }
  }
  .top-viewcart-buttons {
    .viewcart-buttons-panel {
      .go-shopping {
        display: none;
      }
    }
  }
  .sample {
    .remove_link {
      display: none;
    }
  }
  .guarantee-panel {
    margin-top: 0 !important;
  }
  .order-summary__shipping-method,
  .order-summary__shipping {
    display: block;
  }
  &__sidebar {
    .tab-content {
      clear: both;
    }
    .tab-pane {
      @include transition(opacity 1s ease);
      opacity: 0;
      height: 0;
      overflow: hidden;
    }
    .tab-pane.active {
      display: block;
      opacity: 1;
      height: auto;
    }
    .panel {
      margin-top: 0;
      margin-bottom: 32px;
    }
    .product-list {
      min-height: 0;
      overflow: hidden;
    }
    .tab-content .product {
      width: 100%;
      margin: 0;
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid $color-light-gray;
    }
    .tab-content .product:first-child {
      margin-top: 0;
      border-top: none;
    }
    .tab-content .product img {
      width: auto;
      height: 100px;
    }
    .offer {
      padding: 0 10px;
    }
    .product-img {
      max-width: 140px;
      margin: 0;
      padding: 0;
      float: left;
      width: 40%;
    }
    .details {
      margin: 0 0 0 45%;
      padding: 0;
      width: 55%;
    }
    #checkout_samples a {
      text-decoration: underline;
    }
  }
  &__sidebar:after {
    width: 35.15625%;
    left: 64.84375%;
  }
  .checkout__sidebar {
    .viewcart-panel {
      .viewcart-panel__title {
        a {
          width: 26%;
          float: left;
        }
      }
      .viewcart-header {
        padding-bottom: 25px;
      }
      .cart-item__desc {
        width: 60%;
      }
    }
  }
}
.page-wrapper {
  .page-header {
    .header-items {
      .page-utilities {
        &-loyalty-elc-nodeblock {
          float: left;
        }
        &__account-button {
          .user-login-state {
            position: static;
            min-width: 0;
          }
        }
      }
    }
  }
  .viewcart .checkout {
    &__content {
      @media #{$large-up} {
        width: 64.84375%;
      }
    }
    &__sidebar {
      width: 35.15625%;
      @media #{$medium-only} {
        width: 100%;
      }
    }
    &__sidebar:after {
      width: 35.15625%;
      left: 64.84375%;
    }
    &__sidebar .offer-code-panel {
      margin-top: 0;
    }
  }
  .pg_wrapper.samples-page {
    #checkout_samples {
      .samples {
        .offer {
          .product-list {
            .product {
              height: 492px !important;
            }
          }
        }
      }
    }
  }
}
.samples-page .skus {
  bottom: 10px;
}
.shipping-panel {
  select.SELECTED_ADDRESS_ID.display-select {
    display: block !important;
  }
}
.registration-panel {
  .btn.edit {
    display: none !important;
  }
  &.display .btn.edit {
    display: block !important;
  }
}
.shipping-edit-address-content__content,
.payment-container {
  .address-form {
    .ship-type {
      display: block;
    }
  }
  .address-to-use.radio {
    &:last-child {
      margin-left: 0 !important;
    }
  }
}
.invoice_container {
  position: relative;
  padding: 20px 20px 0 10px;
  .menu-item-blocks {
    display: none;
  }
  .messages {
    background-color: $color-white;
    color: $color-light-maroon;
    border: 0;
  }
  .invoice_wrapper {
    float: left;
    width: 50%;
    margin-bottom: 20px;
    .field-are-required {
      right: auto;
    }
  }
  .form-container {
    .form-item {
      margin: 0.5em 0.5em 0.5em 0;
      display: inline-block;
      width: 48%;
      label {
        display: block;
      }
      input {
        width: 100%;
      }
    }
  }
  #continue-btn {
    .submit {
      float: left;
      input {
        margin: 0.5em;
      }
    }
  }
}
.reciept-section {
  .order-details-page-reciept {
    &:before {
      background-color: $color-white !important;
    }
    .sidebar-page__content {
      width: 100%;
      padding: 165px 0 0 0 !important;
      .order-summary {
        padding: 0 20px;
      }
      .cart-item-table {
        width: 98%;
        margin: 0 auto;
      }
      footer {
        margin-top: 150px;
      }
      .basic-textarea-v1 {
        padding-right: 35px;
      }
    }
  }
}
.order-details-page {
  .order-summary {
    margin-bottom: 0;
  }
  .cart-item-table {
    border-top: 1px solid $color-light-gray;
  }
}
.order-details {
  .order-details-page-reciept {
    .sidebar-page__content {
      table {
        tbody tr td {
          table {
            tbody tr td {
              vertical-align: top;
            }
          }
        }
      }
      .shipment {
        h4 {
          margin-bottom: 10px;
        }
        table {
          thead tr th {
            padding-top: 10px;
          }
        }
      }
    }
  }
}
#cboxContent {
  #cboxLoadedContent {
    .field-content {
      .customer-service--returns {
        .sidebar-page__content {
          .basic-textarea-v1 {
            padding-right: 35px;
          }
        }
      }
    }
  }
}
.order-summary-panel {
  .order-summary__content {
    .label {
      width: 70%;
      float: left;
      text-align: left;
      line-height: 1.5;
      margin: 0;
      margin-bottom: 6px;
    }
    .value {
      float: right;
      margin: 0;
      width: 30%;
      line-height: 1.5;
      text-align: right;
      margin-bottom: 6px;
    }
  }
}
#viewcart {
  .recommended-products-panel__content {
    .recommended-products__list {
      .sku-brief-editorial {
        .sku-brief-checkout__controls {
          .sku-brief-editorial__reviews {
            height: 20px;
          }
        }
      }
    }
  }
}
