.page-sticky-footer {
  .page-sticky-footer__left {
    .node-elc-nodeblock {
      &.my-feed-elc-nodeblock {
        display: none;
      }
      &.special-offers-elc-nodeblock {
        border-left: none;
        padding: 0;
      }
    }
    .special-offers-drawer {
      .drawer-formatter__trigger {
        font-weight: normal;
      }
    }
  }
  .page-sticky-footer__right {
    ul {
      &.promo-messages {
        li {
          a {
            b {
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}
