.page-wrapper {
  .spp {
    .product-full {
      h4.product_brief__sub-header {
        letter-spacing: -0.005em;
        font-size: 30px;
        font-family: $optimaregular;
      }
    }
  }
  .page-utilities__account {
    @media #{$medium-up} {
      left: -20px;
    }
    @media #{$xlarge-up} {
      left: -10px;
    }
  }
}

.elc-product-quick-view-details-wrapper {
  [class^='ProductQuickViewContent__'] {
    .elc-product-quantity-wrapper {
      width: 206px;
    }
  }
  .elc-product-cta-wrapper {
    margin-#{$ldirection}: 110px;
  }
}

.sd-product-grid
  .elc-grid-item-product
  .elc-product-quick-view-button-wrapper
  .elc-product-quick-view-button {
  padding: 0 10px;
}

a.selectbox {
  &.product-full__mini-bag-quantity,
  &.product-full__quantity,
  &.search-product__quantity {
    float: $ldirection;
    width: 125px;
  }
}

#colorbox.colorbox__quickshop {
  .quickshop__image-container {
    .quickshop__image {
      img {
        width: 100%;
      }
    }
  }
  .quickshop__description {
    .quickshop__description-section {
      .quickshop__headers {
        .quickshop__header {
          font-size: 40px;
        }
        .quickshop__sub-header {
          font-size: 24px;
        }
      }
    }
  }
}

.page-footer {
  .page-sticky-footer {
    .footer-live-chat {
      cursor: pointer;
    }
  }
}

.section-esearch {
  .search-product {
    .search-product__details {
      .search-product__quantity {
        width: 135px;
      }
      .btn-add-to-bag {
        width: 180px;
        height: auto;
        line-height: normal;
        padding: 4px 10px;
      }
    }
  }
}

.elc-product-quick-view-wrapper {
  .elc-right-column-section {
    .elc-spinner-selector {
      width: auto;
      &-button {
        width: 30%;
        .elc-minus-icon,
        .elc-plus-icon {
          margin-#{$ldirection}: 20%;
        }
      }
    }
    .elc-product-cta-wrapper {
      margin-#{$ldirection}: 110px;
    }
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $optimalight;
}

.tabbed-products-block {
  &__header {
    .tabbed-products-font & {
      font-family: $optimalight !important;
    }
  }
}

.el-search-block {
  input[type='text'].form-text {
    font-family: $optimalight !important;
  }
}

.font--optima-light {
  font-family: $optimalight;
}

.elb-privacy-policy {
  h2 {
    font-size: 50px;
  }
}
