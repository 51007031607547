.page-wrapper {
  .registration-page__content {
    fieldset.registration-page__birthday {
      .sex_container {
        width: 100%;
        margin-top: 30px;
      }
    }
  }
  .address-form__ship-type-container {
    display: block;
  }
  .account-body {
    .order-status-table {
      .order-status-table__row--header {
        display: table-row;
      }
      .order-status-table__details {
        .sku-brief-editorial {
          .sku-brief-editorial__bottom {
            .write-review-link {
              display: block;
            }
            .view-details-link {
              display: none;
            }
          }
        }
      }
    }
  }
  .page-navigation {
    .page-navigation__supplemental {
      .responsive-carousel-nav--visible {
        height: auto;
        overflow: visible;
      }
    }
  }
  .page-footer {
    z-index: 99;
  }
  .account-wishlist {
    .sku-brief-editorial {
      .sku-brief-editorial__controls {
        height: 110px;
        display: table-cell;
        text-align: center;
        vertical-align: bottom;
        width: 329px;
        .sku-brief-editorial__price {
          margin-bottom: 7px;
        }
      }
      .sku-brief-editorial__bottom {
        .write-review-link {
          display: none;
        }
        .view-details-link {
          display: none;
        }
      }
      .text-product-listing-names-link {
        display: block;
        height: 100px;
        margin: auto;
        min-height: 0;
        width: 150px;
      }
    }
  }
}

.account-utilities {
  .account-utilities__header {
    margin-bottom: 17px;
  }
}
.sidebar-menu {
  .sidebar-menu__item {
    margin: 0 0 19px;
  }
}
.sidebar-chat {
  .sidebar-chat__header {
    font-size: 40px;
  }
}
.sidebar-page::before {
  max-width: 255px;
}
.sidebar-page__sidebar {
  max-width: 255px;
}
section.account-utilities {
  ul.sidebar-menu {
    li.sidebar-menu__item {
      a.account-utilities__link.active {
        text-decoration: none;
        color: $color-darker-gray;
      }
    }
  }
}

fieldset.registration-page__personal-info {
  .form-item.csrHide {
    margin-top: 25px;
  }
}
